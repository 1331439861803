import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"
import Layout from "../relaunch/components/layouts/layout/layout"
import { BlogMultipleCategories } from "../relaunch/components/blog/multiple-categories/blog-multiple-categories"
import blogContent from "../page-content/content-blog.json"
import {
  BlogpostMetadata,
  getBlogpostMetadata,
} from "../relaunch/components/blog/blog.model"

export type BlogProps = {
  pageContext?: {
    selectedCategory?: string
  }
}

function categorize(blogPosts: BlogpostMetadata[]) {
  const categoryToPostsMap = blogPosts.reduce((byCategory, post) => {
    const category = post.category
    return {
      ...byCategory,
      [category]: [...(byCategory[category] ?? []), post],
    }
  }, {} as Record<string, BlogpostMetadata[]>)
  return Object.entries(categoryToPostsMap)
    .sort(([name1], [name2]) => {
      // Ensure that "Other" comes last!
      switch ("Other") {
        case name1:
          return 1
        case name2:
          return -1
        default:
          return name1.localeCompare(name2)
      }
    })
    .map(([name, articles]) => ({
      name,
      articles,
    }))
}

const Blog = ({ pageContext }: BlogProps) => {
  const queryResult = useStaticQuery<Queries.blogListingQuery>(graphql`
    query blogListing {
      allMdx(
        filter: {
          fileAbsolutePath: { regex: "/markdown/blog/" }
          frontmatter: { published: { ne: false } }
        }
        sort: { fields: [frontmatter___publishedAt], order: DESC }
      ) {
        edges {
          node {
            ...BlogpostMetadata
          }
        }
      }
    }
  `)

  const blogPosts = useMemo(
    () =>
      queryResult?.allMdx?.edges?.map(({ node }) =>
        getBlogpostMetadata(node),
      ) ?? [],
    [queryResult],
  )

  const featuredPost = useMemo(() => {
    const originalFeatured =
      blogPosts.find((post) => post.url === blogContent.featured.big) ??
      blogPosts[0]
    return {
      ...originalFeatured,
      category: "Featured",
      linkToCategory: "/blog",
    }
  }, [blogPosts])

  const withoutFeaturedIfWouldBeFirst = ([
    first,
    ...rest
  ]: BlogpostMetadata[]) => [
    ...(first.url === featuredPost.url ? [] : [first]),
    ...rest,
  ]

  const categories = useMemo(
    () => [
      { name: "All", articles: withoutFeaturedIfWouldBeFirst(blogPosts) },
      ...categorize(blogPosts),
    ],
    [blogPosts],
  )
  const initiallySelectedCategoryIndex = Math.max(
    0,
    categories.findIndex(
      (category) =>
        category.name.toLowerCase() === pageContext?.selectedCategory,
    ),
  )
  return (
    <Layout>
      <BlogMultipleCategories
        featured={featuredPost}
        categories={categories}
        initiallySelectedCategoryIndex={initiallySelectedCategoryIndex}
      />
    </Layout>
  )
}

export default Blog

export const Head = () => {
  return (
    <>
      <title>{blogContent.seo.title}</title>
      {blogContent.seo.description && (
        <meta name="description" content={blogContent.seo.description} />
      )}
      <meta property="og:title" content={blogContent.seo.title} />
      {blogContent.seo.description && (
        <meta property="og:description" content={blogContent.seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={blogContent.seo.title} />
      {blogContent.seo.description && (
        <meta
          property="twitter:description"
          content={blogContent.seo.description}
        />
      )}
    </>
  )
}
