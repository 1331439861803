import { Tab } from "@headlessui/react"
import { Divider } from "@ory/elements"
import classNames from "classnames"
import { Fragment } from "react"
import Section from "../../freestanding/section"
import { BlogCard } from "../card/blog-card"
import { BlogSubscribeCta } from "../cta/blog-subscribe-cta"
import { BlogpostMetadata } from "../blog.model"

type Category = {
  name: string
  articles: BlogpostMetadata[]
}

export type BlogMultipleCategoriesProps = {
  featured: BlogpostMetadata
  categories: Array<Category>
  initiallySelectedCategoryIndex?: number
}

const getTabPanelIdForCategory = (category: Category): string =>
  `blog-category-tab-${category.name
    .toLowerCase()
    .replaceAll(/[^a-z]+/gm, "-")}`

export const BlogMultipleCategories = ({
  featured,
  categories,
  initiallySelectedCategoryIndex,
}: BlogMultipleCategoriesProps) => (
  <Section className="gap-8">
    <div className="flex flex-col gap-12 p-2">
      <h1 className="mb-4 text-4xl font-semibold leading-none text-indigo-900 md:mb-24 md:text-5xl">
        Ory Blog & Articles
      </h1>
      <BlogCard metadata={featured} className="p-2" />
      <BlogSubscribeCta fullWidth />
    </div>
    <div className="flex flex-col gap-12 p-2">
      <Tab.Group defaultIndex={initiallySelectedCategoryIndex}>
        <Tab.List className="flex space-x-6 overflow-x-auto border-b border-gray-200">
          {categories.map((category) => (
            <Tab as={Fragment} key={category.name}>
              <button
                className={classNames(
                  "dark:hover:border-docktetest-50 dark:text-gray-300 dark:hover:text-cyan-50 border-transparent text-gray-600 hover:border-indigo-900 hover:text-indigo-900 ui-selected:border-cyan-500 ui-selected:text-cyan-500",
                  "whitespace-nowrap rounded-none border-b-2 border-solid py-6 text-sm font-medium",
                )}
                aria-controls={getTabPanelIdForCategory(category)}
              >
                {category.name}
              </button>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {categories.map((category) => (
            <Tab.Panel
              key={category.name}
              id={getTabPanelIdForCategory(category)}
              className="flex flex-col gap-8"
            >
              {category.articles.map((article, index) => (
                <Fragment key={article.title}>
                  {index !== 0 && <Divider fullWidth />}
                  <BlogCard metadata={article} />
                </Fragment>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  </Section>
)
