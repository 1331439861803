import classNames from "classnames"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Avatar } from "../../freestanding/avatar/avatar"
import IconLink from "../../freestanding/icon-link/icon-link"
import Overline from "../../freestanding/overline/overline"
import { BlogpostMetadata } from "../blog.model"

export type BlogCardProps = {
  metadata: BlogpostMetadata
  /**
   * @default false
   */
  className?: string
}

export const BlogCard = ({ metadata, className }: BlogCardProps) => {
  const {
    linkToCategory,
    category,
    url,
    title,
    authors,
    publishedAtHumanReadable,
    publishedAtMachineReadable,
    teaser,
    // featuredImage,
  } = metadata
  // TODO: conclusively decide whether the image should be shown here
  //  and remove the corresponding code if it's not used after all
  const image = getImage(/*featuredImage ??*/ null)

  return (
    <article className={classNames("flex flex-col pt-1", className)}>
      <Link to={linkToCategory}>
        <Overline title={category} className="mb-5" />
      </Link>
      <Link to={url} className="group flex flex-col">
        <div className="mb-6 flex flex-col sm:flex-row sm:justify-between">
          <h2 className="mb-4 text-3xl font-semibold text-indigo-900 sm:mb-0 sm:flex-[1]">
            {title}
          </h2>
          <div className="flex flex-wrap gap-4 sm:flex-[1] sm:justify-end sm:self-start">
            {authors.map((author, index) => (
              <Avatar
                {...{
                  ...author,
                  // Prevent "link inside of link" error
                  link: undefined,
                }}
                key={index}
              />
            ))}
          </div>
        </div>
        <time
          dateTime={publishedAtMachineReadable}
          className="mb-4 text-[13.2px] text-gray-600"
        >
          {publishedAtHumanReadable}
        </time>
        <div className="flex flex-col gap-6 lg:flex-row-reverse">
          {image && (
            <GatsbyImage
              className="aspect-[1.91/1] object-cover lg:h-fit lg:w-[50%] lg:shrink-0"
              alt={title}
              image={image}
            />
          )}
          <div className="flex gap-6 lg:flex-1 lg:flex-col">
            <p className="flex-1 text-gray-600 lg:flex-[0]">{teaser}</p>
            <IconLink
              ariaLabel="Read"
              className="max-lg:self-end lg:py-0"
              title="Read"
              titleClassName="max-lg:hidden"
            />
          </div>
        </div>
      </Link>
    </article>
  )
}
